.Items {
    padding: 0.625rem;
    padding-bottom: 1.875rem;
    min-height: 50vh;
    position: relative;

    button {
        color: inherit;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }
}
