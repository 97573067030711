.Comment {
    display: block;
    font-size: 0.75rem;

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    button {
        color: inherit;
        background: none;
        border: none;
        padding: 0;
    }

    // &_header {
    // }

    &_comments {
        &_body {
            display: block;
            // margin: 1.25rem 0;
            a {
                text-decoration: underline;
                color: blue;
            }
        }
    }
    // &_nested {
    // }
}
