.StoryItem {
    display: block;
    min-height: 1.5rem;

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    .subtext {
        font-size: 0.5rem;
    }
}
