.Stories {
  padding: 0.625rem;
  padding-bottom: 1.875rem;
  min-height: 50vh;
  position: relative;
  & ul {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    list-style: none;
  }
}
